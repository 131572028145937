import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { toBase64, uploadToS3 } from '../../utils/imageUtils';
import ErrorLogger from '../../utils/errorLogger';
import { MediaStore } from '../../stores';
import { Transducer } from '../../utils/transducer';
import { Capacitor as CapacitorCore } from '@capacitor/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '../Icon';
import ListItemText from '@material-ui/core/ListItemText';
import BottomSheet from '../bottom-sheet';
import ModalDialog from '../ModalDialog';
import { openAppSettings } from '../../App';
const { Filesystem, Photopicker: MediaPicker } = window.Capacitor.Plugins;

function FileUploader(props) {
  const [showImageMenuOptions, setShowImageMenuOptions] = useState(false);
  const [showVideoMenuOptions, setShowVideoMenuOptions] = useState(false);
  const [mediaUploadStatus, setMediaUploadStatus] = useState({});

  const videoGalleryRef = useRef(null);
  const imageGalleryRef = useRef(null);
  const videoCaptureRef = useRef(null);
  const imageCaptureRef = useRef(null);

  useEffect(() => {
    console.log('Media Upload Status:');
    console.log(mediaUploadStatus);

    const fileKeys = Object.keys(mediaUploadStatus);
    if (fileKeys && fileKeys.length === props.files.length) {
      console.log('All statuses have been updated');
      let finishedUploading = true;
      fileKeys.forEach((key) => {
        if (mediaUploadStatus[key] !== 'uploaded' && mediaUploadStatus[key] !== 'cancelled') {
          finishedUploading = false;
        }
      });

      if (finishedUploading) {
        console.log('Finished uploading');
        if (typeof props.uploading === 'function') {
          console.log('Calling props.uploading...');
          props.uploading(false);
        }
      }
    }
  }, [mediaUploadStatus]);

  const cancelUpload = (filename) => {
    console.log('Cancelled upload...');
    // TODO Actually cancel it?
    if (mediaUploadStatus[filename] !== 'uploaded') {
      setMediaUploadStatus((prevStatus) => ({
        ...prevStatus,
        [filename]: 'cancelled',
      }));
      Sentry.captureMessage('The file upload took longer than 6 seconds');
    }
  };

  // TODO Still want to make this a helper function - needs used by the media cropper
  const uploadMedia = async (blob, base64, type, id) => {
    // console.log('Existing UUID from uploader:');
    // console.log(id);
    // console.log('Base64:');
    // console.log(base64);
    const extension = base64 ? base64.split(';')[0].split('/')[1] : blob.name.split('.').pop();

    // console.log(blob.type);
    // console.log(blob);

    const uuid = id || uuidv4();
    const filename = `${props.filenameTemplate().replace('uuid', uuid)}.${extension}`;
    // console.log(`Filename: ${filename}`);

    // INFO If it takes longer than 5 seconds cancel and console.debug admins - we may need to resize media a little before uploading
    setTimeout(() => cancelUpload(filename), 6000);

    // console.log('Uploading to media server...');
    setTimeout(() => {
      setMediaUploadStatus((prevStatus) => ({
        ...prevStatus,
        [filename]: 'uploading',
      }));
    }, 100);

    console.log('Uploading to S3...');
    await uploadToS3(blob, props.folder, filename, uuid, (result) => {
      // This allows us to get the media URL as soon as it's ready rather than wait for the final upload
      const file = {
        ...result.data,
        uuid,
        type,
        src: base64,
      };
      for (let i = 0; i < props.files.length; i++) {
        const data = props.files[i];
        if (data.uuid && data.uuid === uuid) {
          props.files[i] = file;
        }
      }
      MediaStore.update((s) => {
        s.mediaMap[result.data.media_url] = URL.createObjectURL(blob);
      });
      // FIXME Different callback for this? onMediaUrl or something?
      if (typeof props.onUploading === 'function') {
        setTimeout(() => {
          props.onUploading(file, [...props.files]);
        }, 50);
      }
    }).then((res) => {
      console.log('Uploaded to media server!');
      console.log(JSON.stringify(res));
      Sentry.setExtra('uploadMedia_res_data', JSON.stringify(res.data));
      let type = 'image';
      if (blob.type.indexOf('video/') !== -1) {
        type = 'video';
      }
      const file = {
        ...res.data,
        uuid,
        type,
        src: base64,
      };
      for (let i = 0; i < props.files.length; i++) {
        const data = props.files[i];
        if (data.uuid && data.uuid === uuid) {
          props.files[i] = file;
        }
      }
      // console.log('Selected files:');
      // console.log(props.files);
      if (typeof props.onUploaded === 'function') {
        // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
        props.onUploaded(file, [...props.files]);
      }
      setTimeout(() => {
        setMediaUploadStatus((prevStatus) => ({
          ...prevStatus,
          [filename]: 'uploaded',
        }));
      }, 100);
    }).catch((err) => {
      console.log(err);
      if (typeof props.uploading === 'function') {
        props.uploading(false);
      }
      ErrorLogger.captureException(err);
    });
  };

  // function arrayBufferToBase64(buffer) {
  //   let binary = '';
  //   const bytes = [].slice.call(new Uint8Array(buffer));
  //   bytes.forEach((b) => binary += String.fromCharCode(b));
  //   return window.btoa(binary);
  // }

  const getExtensionFromUri = (uri) => uri.split('').reverse().join('').split('.')[0].split('').reverse().join('');

  const blobAtFilepath = (url, filename, mimeType) => (fetch(url, { mode: 'no-cors' })
    .then((res) => res.arrayBuffer())
    .then((buffer) => new File([buffer], filename, { type: mimeType }))
    .catch((err) => console.log(err.message))
  );

  const base64AtFilepath = (path) => new Promise((resolve) => {
    Filesystem.readFile({ path }).then((contents) => {
      const extension = getExtensionFromUri(path);
      const base64Flag = `data:${props.accept.replace('/*', '')}/${extension};base64,`;
      // alert(JSON.stringify(`${base64Flag}${contents.data}`));
      resolve(`${base64Flag}${contents.data}`);
    }).catch((err) => Sentry.captureException(err));
  });

  /**
   * Uses the local media URI to create the base 64 version of the media selected. This is used on Native Android only.
   * @param mediaUri
   * @param type
   * @returns {Promise<void>}
   */
  const transformAndUpload = async (mediaUri, type) => {
    const uuid = uuidv4();
    const file = {
      uuid,
      type,
      src: window.Capacitor.convertFileSrc(mediaUri),
    };
    props.files.push(file);
    if (typeof props.uploading === 'function') {
      props.uploading(true);
    }
    if (typeof props.onUploading === 'function') {
      // console.log('Triggering onUploading!');
      // INFO Timeout helps ensure that this triggers after each item, not just the first
      setTimeout(() => {
        // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
        props.onUploading(file, [...props.files]);
      }, 50);
    }

    try {
      const extension = getExtensionFromUri(mediaUri);
      const mimeType = `${props.accept.replace('/*', '')}/${extension}`;
      if (props.accept === 'image/*') {
        // alert(mediaUri);
        await base64AtFilepath(mediaUri).then(async (base64) => {
          await blobAtFilepath(base64, `temp.${extension}`, mimeType).then(async (blob) => {
            console.log('File:');
            console.log(JSON.stringify(blob.type));
            console.log('Uploading...');
            await uploadMedia(blob, base64, type, uuid);
          });
        });
      } else if (props.accept === 'video/*') {
        // alert(mediaUri);
        await blobAtFilepath(CapacitorCore.convertFileSrc(mediaUri), `temp.${extension}`, mimeType).then(async (blob) => {
          console.log('File:');
          console.log(JSON.stringify(blob.type));
          console.log('Uploading...');
          await uploadMedia(blob, null, type, uuid);
        });
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  /**
   * This is used on the web only
   * @param blob
   * @param type
   */
  const convertMediaAndUpload = async (blob, type) => {
    console.log('Blob:');
    console.log(blob);
    await toBase64(blob).then(async (imageFile) => {
      const uuid = uuidv4();
      const file = {
        uuid,
        type,
        src: imageFile,
      };
      props.files.push(file);
      if (typeof props.uploading === 'function') {
        props.uploading(true);
      }
      if (typeof props.onUploading === 'function') {
        // console.log('Triggering onUploading!');
        // INFO Timeout helps ensure that this triggers after each item, not just the first
        setTimeout(() => {
          // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
          props.onUploading(file, [...props.files]);
        }, 50);
      }
      await uploadMedia(blob, imageFile, type, uuid);
    });
  };

  const handleImageFiles = async (files) => {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      promises.push(convertMediaAndUpload(files[i], 'image'));
    }
    await Promise.all(promises);
  };

  const imageFromCamera = async () => {
    setShowImageMenuOptions(false);
    if (imageCaptureRef && imageCaptureRef.current) {
      imageCaptureRef.current.click();
    }
  };

  const imagesFromGallery = async () => {
    setShowImageMenuOptions(false);
    if (window.Capacitor.isNative) {
      const result = await MediaPicker.getMedia({ multiple: props.multiple, acceptType: 'image/*' });
      if (result.selected) {
        const transforms = [];
        for (const url of result.urls) {
          if (url) {
            // alert(url);
            transforms.push(transformAndUpload(`file://${url}`, 'image'));
          }
        }
        await Promise.all(transforms);
      } else {
        // FIXME Do what?
        alert(JSON.stringify(result));
        if (result.error === 'User denied permission') {
          ModalDialog.show({
            title: 'Unable to access photos',
            message: 'You must grant Boxpressd permission to access your photos through your System Settings.',
            buttons: [{
              label: 'Open Settings',
              onClick: () => {
                ModalDialog.close();
                openAppSettings();
              },
            }, {
              label: 'Dismiss',
              onClick: () => {
                ModalDialog.close();
              },
            }],
          });
        }
      }
    } else if (imageGalleryRef && imageGalleryRef.current) {
      imageGalleryRef.current.click();
    }
  };

  const videoFromGallery = async () => {
    setShowVideoMenuOptions(false);
    // TODO For now, just use the web version - we need to tighten up the native implementation
    // if (window.Capacitor.isNative) {
    //   const result = await MediaPicker.getMedia({ multiple: false, acceptType: 'video/*' });
    //   if (result.selected) {
    //     if (result.urls.length) {
    //       // alert(result.urls[0]);
    //       console.log(result.urls[0]);
    //       await transformAndUpload(`file://${result.urls[0]}`, 'video');
    //     }
    //   } else {
    //     // FIXME Do what?
    //     alert(JSON.stringify(result));
    //   }
    // } else
    if (videoGalleryRef && videoGalleryRef.current) {
      videoGalleryRef.current.click();
    }
  };

  const videoFromCamera = async () => {
    setShowVideoMenuOptions(false);
    // FIXME Consider an in-house solution with a UI on top - https://github.com/TeamHive/capacitor-video-recorder
    if (videoCaptureRef && videoCaptureRef.current) {
      videoCaptureRef.current.click();
    }
  };

  const onInputFileAdded = async (e) => {
    // console.log('Event:');
    // console.log(e);
    e.persist();
    if (typeof props.onChange === 'function') {
      props.onChange(e);
    }
    // console.log('Files:');
    // console.log(e.target.files);
    if (e.target.files[0].type.indexOf('image/') !== -1) {
      // console.log('Got image file!');
      await handleImageFiles(e.target.files);
    } else if (e.target.files[0].type.indexOf('video/') !== -1) {
      const videoFile = e.target.files[0];
      const uuid = uuidv4();
      const file = {
        uuid,
        type: 'video',
        src: videoFile, // FIXME This isn't right - what to use for video? Or wait til it uploads?
      };
      props.files.push(file);
      if (typeof props.uploading === 'function') {
        props.uploading(true);
      }
      if (typeof props.onUploading === 'function') {
        setTimeout(() => {
          // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
          props.onUploading(file, [...props.files]);
        }, 50);
      }
      await uploadMedia(e.target.files[0], null, 'video', uuid);
    } else {
      // TODO? Right now we only handle images and videos on the server - no plans yet to allow other files, but may be useful for CSV and others
    }
  };

  if ((window.Capacitor.isNative && window.Capacitor.platform === 'android') || (Transducer.isNative() && Transducer.getPlatform() === 'android')) {
    return (
      <div
        ref={props.forwardedRef}
        onClick={async () => {
          if (props.accept === 'image/*') {
            BottomSheet.show({
              items: [{
                content: 'From Photos',
                icon: 'image',
                value: 'gallery',
                onClick: imagesFromGallery,
              }, {
                content: 'Take Picture',
                icon: 'camera',
                value: 'camera',
                onClick: imageFromCamera,
              }],
            });
          } else if (props.accept === 'video/*') {
            BottomSheet.show({
              items: [{
                content: 'From Gallery',
                icon: 'film',
                value: 'gallery',
                onClick: videoFromGallery,
              }, {
                content: 'Take Video',
                icon: 'video',
                value: 'camera',
                onClick: videoFromCamera,
              }],
            });
          }
        }}
      >
        {props.accept === 'video/*' && (
          <input
            style={{ display: 'none' }}
            ref={videoCaptureRef}
            type="file"
            accept="video/*"
            capture
            onChange={onInputFileAdded}
          />
        )}
        {props.accept === 'video/*' && (
          <input
            style={{ display: 'none' }}
            ref={videoGalleryRef}
            type="file"
            accept="video/*"
            onChange={onInputFileAdded}
          />
        )}
        {props.accept === 'image/*' && (
          <input
            style={{ display: 'none' }}
            ref={imageCaptureRef}
            type="file"
            accept="image/*"
            capture
            onChange={onInputFileAdded}
          />
        )}
        {props.accept === 'image/*' && (
          <input
            style={{ display: 'none' }}
            ref={imageGalleryRef}
            multiple={props.multiple}
            type="file"
            accept="image/*"
            onChange={onInputFileAdded}
          />
        )}
      </div>
    );
  }
  // FIXME Switch it to use this logic - problem now is that each needs the anchor reference to the chip that was clicked
  if (Transducer.isNative() && Transducer.getPlatform() === 'android') {
    return (
      <div
        className="file-picker-options"
        ref={props.forwardedRef}
        onClick={async () => {
          if (props.accept === 'image/*') {
            setShowImageMenuOptions(true);
          } else if (props.accept === 'video/*') {
            setShowVideoMenuOptions(true);
          }
        }}
      >
        {props.accept === 'video/*' && (
          <input
            style={{ display: 'none' }}
            ref={videoCaptureRef}
            type="file"
            accept="video/*"
            capture
            onChange={onInputFileAdded}
          />
        )}
        {props.accept === 'video/*' && (
          <input
            style={{ display: 'none' }}
            ref={videoGalleryRef}
            type="file"
            accept="video/*"
            onChange={onInputFileAdded}
          />
        )}
        {props.accept === 'image/*' && (
          <input
            style={{ display: 'none' }}
            ref={imageCaptureRef}
            type="file"
            accept="image/*"
            capture
            onChange={onInputFileAdded}
          />
        )}
        {props.accept === 'image/*' && (
          <input
            style={{ display: 'none' }}
            ref={imageGalleryRef}
            multiple={props.multiple}
            type="file"
            accept="image/*"
            onChange={onInputFileAdded}
          />
        )}
        <Menu
          id="image-picker-options"
          aria-labelledby="image-picker-options"
          keepMounted
          anchorEl={props.menuAnchor}
          open={showImageMenuOptions}
          onClose={() => setShowImageMenuOptions(false)}
          onBlur={() => setShowImageMenuOptions(false)}
        >
          <MenuItem onClick={imagesFromGallery}>
            <ListItemIcon>
              <Icon name="image" />
            </ListItemIcon>
            <ListItemText>From Gallery</ListItemText>
          </MenuItem>
          <MenuItem onClick={imageFromCamera}>
            <ListItemIcon>
              <Icon name="camera" />
            </ListItemIcon>
            <ListItemText>Take Photo</ListItemText>
          </MenuItem>
        </Menu>
        <Menu
          id="video-picker-options"
          aria-labelledby="video-picker-options"
          keepMounted
          anchorEl={props.menuAnchor}
          open={showVideoMenuOptions}
          onClose={() => setShowVideoMenuOptions(false)}
          onBlur={() => setShowVideoMenuOptions(false)}
        >
          <MenuItem onClick={videoFromGallery}>
            <ListItemIcon>
              <Icon name="film" />
            </ListItemIcon>
            <ListItemText>From Gallery</ListItemText>
          </MenuItem>
          <MenuItem onClick={videoFromCamera}>
            <ListItemIcon>
              <Icon name="camera" />
            </ListItemIcon>
            <ListItemText>Take Video</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    );
  }
  return (
    <input
      id={props.id}
      type="file"
      multiple={props.multiple}
      accept={props.accept}
      ref={props.forwardedRef}
      style={{ display: 'none' }}
      onChange={onInputFileAdded}
    />
  );
}

FileUploader.propTypes = {
  id: PropTypes.string,
  files: PropTypes.array.isRequired,
  folder: PropTypes.string.isRequired,
  filenameTemplate: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  uploading: PropTypes.func,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  onUploading: PropTypes.func,
  onUploaded: PropTypes.func,
};

FileUploader.defaultProps = {
  id: undefined,
  multiple: false,
  uploading: () => {},
  accept: undefined,
  onChange: () => {},
  onUploading: () => {},
  onUploaded: () => {},
};

export default FileUploader;
